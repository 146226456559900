import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  availableConfigs,
  currConfigId,
  currConfigObject,
  __debugInterval
} from "../providers/app-state";
import { findConfigById } from "../utils/initialize";
//import { writeIatLog } from "../providers/iat-logs";
import SelectTest from "./select-test";

const Home = () => {

  
  const params = useParams();

  const [query] = useSearchParams();

  const configs = useRecoilValue(availableConfigs);
  const [, setDebugInterval] = useRecoilState(__debugInterval);

  const [, setConfigId] = useRecoilState(currConfigId);

  const [skipSelect, setSkipSelect] = useState(false);
  const configObject = useRecoilValue(currConfigObject);
    
  useEffect(() => {

    const testInterval = parseInt((query.get("debug") || "-1"));
    
    if (!isNaN(testInterval) && testInterval > 0){
      console.info(`Debug interval set to ${testInterval}ms`);
      setDebugInterval(testInterval);
    }
    
    if (params.iat) {
      const cfg = findConfigById( params.iat, configs);
      if (cfg  && configs.find(c=>c.id === params.iat)?.active) {
        console.info(`${params.iat} selected from url`);
        setConfigId(cfg.id);
        setSkipSelect(true);
      }
    } else {
      setConfigId(configObject.id);
      setSkipSelect(false);
    }

    return () => {}
  }, [configObject.id, configs, params.iat, query, setConfigId, setDebugInterval]);

  return <SelectTest  selected={ skipSelect ? configObject.id : ""} />
};

export default Home;
