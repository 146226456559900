import { ThemeOptions } from '@mui/material/styles';
import { atom, useRecoilState } from 'recoil';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';

const mainTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#0088cc',
    },
    secondary: {
      main: '#f50057',
    },
  },
  // typography: {
  //   fontFamily: 'Open Sans',
  //   button:{
  //     textTransform: 'none'
  //   }
  // },
};


const appIsDarkMode = atom<boolean|null>({
  key: 'appIsDarkMode',
  default: null
})

const ToggleTheme = () => {
  const [dark, setDark] = useRecoilState(appIsDarkMode);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  
  useEffect(()=>{
    if (dark === null){
      const saved = localStorage.getItem('COLOR_MODE');
      if (saved === null){
        setDark(prefersDarkMode);
      }else{
        setDark(saved==='dark');
      }
    }
  }, [prefersDarkMode, dark, setDark])


  const toggleTheme = () => {
    localStorage.setItem('COLOR_MODE', !dark ? 'dark' : 'light');
    setDark(!dark);
  }

  return (
    <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
    {dark ? <Brightness7Icon /> : <Brightness4Icon />}
  </IconButton>
  );
}

export {appIsDarkMode, mainTheme, ToggleTheme};
