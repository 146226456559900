import { useSetRecoilState } from "recoil";
import { hashtagMap } from "../providers/app-state";
import { useTransliteration } from "../providers/transliterate";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";

import {
  buildHashtagMap,
  HashtagCategories,
} from "../utils/hashtags";
import { mapNameToAutocomplete } from "../utils/autocomplete-names";
import TranslitButton from "../layout/translit-button";
import { IATCategories, IATConfig } from "../iat-types";
import { Typography } from "@mui/material";
import GenderToggleButton from "./gender-button";
import { capitalizeFirstLetter } from "../utils/capitalize";
import { useSnackbar } from "notistack";

const HashtagForm = ({
  cfg,
  onSubmit,
}: {
  cfg: IATConfig;
  onSubmit: (isSubmitted: boolean) => void;
}) => {

  const setHash = useSetRecoilState(hashtagMap);

  const { enqueueSnackbar } = useSnackbar();

  const catRef = useRef<HashtagCategories>({});

  const [, forceUpdate] = useState(0);

  const tl = useTransliteration();

  useEffect(() => {
    catRef.current = buildHashtagMap(cfg);
    forceUpdate(c=>c+1);
  }, [cfg]);

  interface FormProps{
    category: IATCategories;
  }

  const CategoryForm = ({
    category,
  }: FormProps) => {

    const [, forceUpdate] = useState(0);

    const cat = cfg.categories[category];

    return (
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {capitalizeFirstLetter(cat.label.n)}
          </Typography>
          {cat.gender && <GenderToggleButton onChange={
            (newG) => {
              if (catRef.current[category]!==undefined){
                //@ts-ignore
                catRef.current[category].gender = newG
              } 
              }
          }
             />}
        </Stack>
        
        { //@ts-ignore
          Array.from(catRef.current[category].map).map((item, idx) => { // catRef.current[category] || new Map<string,string>([])
          const [key /*, value */] = item;
          return (
            <TextField
              sx={{ mt: 2 }}
              key={key}
              size="small"
              autoComplete={mapNameToAutocomplete(key.substring(1))}
              //error={canSubmit.current && !catRef.current[category]?.map.get(key)}
              required
              label={tl(key.substring(1))}
              value={catRef.current[category]?.map.get(key)}
              //value={contents[idx]}
              type="text"
              title={tl("Обавезно поље")}
              onChange={(e) => {
                catRef.current[category]?.map.set(key, e.target.value);
                //canSubmit.current = validateForm(catRef.current);
                forceUpdate(c=>c+1);
              }}
            ></TextField>
          );
        })}
      </Stack>
    );
  };

  return (
    <Stack sx={{ maxWidth: "md", m: "auto", p: 0 }} spacing={2}>
      <input type="text" hidden autoComplete="none"></input>{" "}
      {/* Chrome workaround for wrong autocomplete */}
      {Object.keys(catRef.current).map((item, i) => {
        return (
          <CategoryForm
            key={i}
            category={item as IATCategories}
          />
        );
      })}
      <TranslitButton
        tooltipText="Сва поља морају бити попуњена за наставак"
        variant="contained"
        fullWidth
        //disabled={canSubmit.current}
        onClick={() => {
          if (!validateForm(catRef.current)){
            enqueueSnackbar(tl(`Сва поља морају бити попуњена за наставак`), {
              variant: "error",
              anchorOrigin: { horizontal: "center", vertical: "bottom" },
              autoHideDuration: 2000,
              preventDuplicate: true
            });
          }else{
            setHash(catRef.current);
            onSubmit(true);
          }
        }}
      >
        Ок
      </TranslitButton>
      <TranslitButton
        tooltipText="Повратак"
        variant="outlined"
        fullWidth
        onClick={() => {
          onSubmit(false);
        }}
      >
        Откажи
      </TranslitButton>
    </Stack>
  );
};

export default HashtagForm;

function validateForm(t: HashtagCategories): boolean {

  let res = true;
  Object.values(t).forEach(o=>{
    if (Array.from(o.map).some((item) => item[1].length === 0)) res = false;
  });
  return res;
}
