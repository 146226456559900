import { useState, FormEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { AuthError, getAuth, signOut } from "firebase/auth";
import { useAuthState, useSignInWithEmailAndPassword, useSendPasswordResetEmail } from 'react-firebase-hooks/auth';

//import { SnackbarKey, SnackbarProvider, useSnackbar } from "notistack";

import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from "@mui/material/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


import AppTitle from "../layout/appbar";
import { useTransliteration } from "./transliterate";
import ConfirmDialog from "./reset-password";

import { ADMIN_URL } from "../constants";
import { isMailValid } from "../utils/email-validation";



const firebaseConfig = {
  apiKey: "AIzaSyB6Veav79TPba220t0hNPzp93OZygH7mOs",
  authDomain: "iat-app-8ee7d.firebaseapp.com",
  projectId: "iat-app-8ee7d",
  storageBucket: "iat-app-8ee7d.appspot.com",
  messagingSenderId: "657747964819",
  appId: "1:657747964819:web:44efcede3d5b5969df6e66",
  measurementId: "G-DQK2H3JZR3"
};

// Initialize Firebase
const iatApp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const firebaseAuth = getAuth(iatApp);


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© '}
      <Link color="inherit" href="https://blur.rs/">
        Blur Studio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

//const isMailValid = (mail:string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(mail);

const authErrorText = (err?: AuthError) => {
  if (!err) return "\u00a0";
  switch(err.code){
    case "auth/user-not-found":
    case "auth/wrong-password":
      return "Погрешан мејл и/или лозинка";
    case "auth/user-disabled":
      return "Кориснику је привремено забрањен приступ"
    default:
      console.error(err);
      return "Грешка при пријави: \n" + err.message;
  }
}

export default function Login() {
  
  const [email, setEmail] = useState("");

    const [
        signInWithEmailAndPassword,
        user,
        loading,
        error,
      ] = useSignInWithEmailAndPassword(firebaseAuth);

      const navigate = useNavigate();
      
      useEffect(() => {
        if (user) navigate(ADMIN_URL, {replace:true})
      }, [user, loading, navigate])
      

      
      const LoginForm = () =>{
        
        const tl = useTransliteration();
        const [formError, setFormError] = useState({invalidEmail: false, emptyPass: false});
        
        const [sendPasswordResetEmail, sendingReset, /*  resetError */] = useSendPasswordResetEmail(firebaseAuth);
        //const { enqueueSnackbar, closeSnackbar } = useSnackbar();
        
        // useEffect(() => {
        //   let errKey: SnackbarKey|null = null;
        //   let sendKey: SnackbarKey|null = null;

        //   if (resetError){
        //     if (sendKey) closeSnackbar(sendKey);
        //     errKey = enqueueSnackbar(tl("Грешка при слању мејла. Покушајте касније."), {variant: "error"})
        //   }
        //   if (sendingReset){
        //     sendKey = enqueueSnackbar(tl("Шаљемо мејл…"), {variant: "info"})
        //   }
        //   return () => {
        //     if (errKey) closeSnackbar(errKey);
        //     if (sendKey) closeSnackbar(sendKey);
        //   }
        // }, [resetError, sendingReset])


        const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const data = new FormData(event.currentTarget);
          const em = (data.get('email') as string).trim();
          const pw = data.get('password') as string;
          //emailRef.current = em;
          setEmail(em);
          if (isMailValid(em) && pw.length >0){
            await signInWithEmailAndPassword(em, pw);
            return;
          }
          setFormError({invalidEmail: !isMailValid(em), emptyPass: pw.length === 0});
        };
      

        return (
          <Container component="main" maxWidth="xs">
              <Box
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {tl("Пријави се")}
                </Typography>
                  <Typography sx={{mt:2}} variant="body2" color="error" align="center">
                    {tl(authErrorText(error))}
                  </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                    defaultValue={email}
                    onBlur={(e)=>setEmail(e.target.value.trim())}
                    error={formError.invalidEmail}
                    helperText={formError.invalidEmail && tl("Неисправан мејл")}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={tl("Мејл адреса")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    error={formError.emptyPass}
                    helperText={formError.emptyPass && tl("Лозинка је неопходна")}
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={tl("Лозинка")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />

                  <LoadingButton
                    loading = {loading}
                    disabled={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {tl("Пријави се")}
                  </LoadingButton>

                  <Grid container>
                    <Tooltip arrow 
                        title={tl("Ако сте заборавили лозинку, попуните имејл адресу и кликните овде да бисте добили мејл са упутством за промену лозинке.")}>
                      <Grid item xs>
                      <Button sx={{textTransform: "none"}}
                        onClick={resetPassword}
                        disabled={!isMailValid(email)}
                      >
                        { sendingReset ? tl("Слање захтева…") : tl("Заборављена лозинка?")}
                      </Button>
                      </Grid>
                    </Tooltip>
                    <Grid item>
                    <Button sx={{textTransform: "none"}}
                      onClick={()=>navigate("/")}
                    >
                        {tl("Иди на почетну страну")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Copyright sx={{ mt: 8, mb: 4 }} />
              <ConfirmDialog 
                open={confirmOpen} 
                email={email} 
                onConfirm={async () => {
                  setConfirmOpen(false);
                  await sendPasswordResetEmail(email);

                }}
                onClose={()=>setConfirmOpen(false)}
              />
            </Container>
        );
      }
      
      const [confirmOpen, setConfirmOpen] = useState(false);

      const resetPassword = () => {
          if (isMailValid(email)){
            setConfirmOpen(true);
          }
      }


      return (
      // <SnackbarProvider>
        <>
          <AppTitle title="Администрација"/>
          <LoginForm/>
        </>
      // </SnackbarProvider>
      );
  }

export const doSignOut = () => {
    signOut(firebaseAuth);
}

export const AuthButton = () => {
  const tl = useTransliteration();
  const [user] = useAuthState(firebaseAuth);
  
  if (!user) return null;

  return (
    <Tooltip title={ tl("Одјави се")}>
    <IconButton sx={{ ml: 1 }} onClick={doSignOut} color="inherit">
        <LogoutIcon />
    </IconButton>
    </Tooltip>
  );
}