import { IATLogEntry } from "../iat-types";

import {ItemValue, Question} from 'survey-core';

const mandatorySurveyFields = ["yob","citySize","gender","edu","finance"] as const;

type ITarget = typeof mandatorySurveyFields[number];

export type SurveyFields = Pick<IATLogEntry, ITarget> & {
  surveyMain?: string;
  surveyRest?: any;
  
  missing: string[];
}

interface ISurveyValidator{
    valid: boolean;
    errors: string[];
    instance?: SurveyFields | null;
}

function extractMandatoryFields(data: Record<string, any>): SurveyFields {
  
    const missing: string[] = [];
  
    console.warn(data)

    // Extract mandatory fields and check for missing ones
    const extractedData = mandatorySurveyFields.reduce((result : Record<string, any>, field) => {
      if (data[field] !== undefined) {
        result[field] = data[field];
      } else {
        missing.push(field);
      }
      return result;
    }, {} as SurveyFields);
  
    if (data.surveyMain) {
      extractedData.surveyMain = data.surveyMain;
      delete data.surveyMain;
    };

    // Extract non-mandatory fields
    const surveyRest = { ...data };
    mandatorySurveyFields.forEach((field) => delete surveyRest[field]);
  
    // Add 'missing' property if there are missing mandatory fields
    if (missing.length > 0) {
      extractedData.missing = missing;
    }
  
    // Add 'surveyRest' property for non-mandatory fields
    if (Object.keys(surveyRest).length > 0) {
      extractedData.surveyRest = surveyRest;
    }
  
    return extractedData as SurveyFields;
  }
  

export function validateResponseFields(surveyResponse: any):ISurveyValidator{

    const res:ISurveyValidator = {valid:false, errors: [], instance: null};

    const extracted = extractMandatoryFields(surveyResponse);
    if (extracted.missing){
        res.errors = extracted.missing;
        return res;
    }
    res.valid = true;
    res.instance = extracted;
    return res;
}

export function extractQuestionValuesAndLabels( question: Question, locale?: string): Map<string, string> {

  // if (question.getType() !== "dropdown") {
  //   throw new Error(`extractOptions: Question is not a dropdown.`);
  // }

  if (!Array.isArray(question.visibleChoices)) {
    const questionName = question.name;
    const msg = `Question '${questionName}' is not multiple choice.`;
    alert(msg);
    throw new Error(msg);
  }

  const extractedData = new Map<string, string>([]);

  // Extract values and labels
  question.visibleChoices.forEach((choice: ItemValue) => {
    const c = choice.locText;
    const choiceLabel =
      c.getLocaleText(locale!) ||
      c.getLocaleText("default");

    extractedData.set(choice.value, choiceLabel);
  });

  return extractedData;
}
