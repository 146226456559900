import { fetchJson } from '../providers/fetch';
import { GET_CFG_DB, GET_AGE_GROUPS_DB } from '../constants';
import { IAgeGroup, IAvailableConfig } from '../iat-types';


export async function getAvailableConfigs(){
    try {
        let cfg = await fetchJson(GET_CFG_DB) as IAvailableConfig[];
        return cfg;
    } catch (error) {
        alert("Грешка при читању конфигурационих фајлова са сервера. Обратите се администратору.");
        throw new Error();
    }

}

// finds config by supplied id. Returns undefined if not found.
export const findConfigById = (id: string, configList:IAvailableConfig[]) => {
    const c = configList.find(c => c.id === id);
    if (!c) return undefined;
    return c.cfg;
  }

export const findFirstActiveConfig = (available: IAvailableConfig[]) => {
    const idx = available.findIndex(c=>c.active !==0);
      if (idx < 0){
        alert("Нема активних тестова!\n");
        //throw new Error("No active tests found.")
        return available[0].cfg;
      }
      return available[idx].cfg;
}

export async function getAgeRanges():Promise<IAgeGroup[]> {
    try {
        let ageGroups = await fetchJson(GET_AGE_GROUPS_DB) as IAgeGroup[];
        return ageGroups;
    } catch (error) {
        alert("Грешка при читању узрасних група. Обратите се администратору.");
        throw new Error();
    }

}
