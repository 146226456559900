import { _log } from "../constants";
import { IGender } from "../iat-types";

export const genderify = (value:string|undefined, gender?: IGender | boolean, splitter="|"):string => {
    if (value===undefined) return "???";
    if (gender===undefined) return value;
    const splitted = value.split(splitter);
    if (splitted.length !== 2) return value;

    const res = gender==="female" ? splitted[1].trim() : splitted[0].trim();
    _log(value, gender, res);
    return res;
}