import {ReactNode} from 'react';
import { AppBar, AppBarProps, Toolbar, Typography } from "@mui/material";
import { ToggleScript, useTransliteration } from "../providers/transliterate";
import { ToggleTheme } from "../theme";
import { ToggleSound } from "../providers/sound";
import { AuthButton } from "../providers/firebase";
import { BackButton } from "../components/backbutton";

interface AppTitleProps extends AppBarProps{
    title?: string;
    noBackButton?: boolean;
    children?: ReactNode;
}

const AppTitle = ({title, color, noBackButton, children}:AppTitleProps) => {
  const tl = useTransliteration();

  return(
    <AppBar 
        color={color || "primary"} 
        enableColorOnDark
        position="sticky"
    >
    <Toolbar variant="dense" style={ noBackButton!==true ? {paddingLeft: 6} : undefined}>
      { (noBackButton!==true) && <BackButton />}
      <Typography noWrap variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {tl(title)}
      </Typography>
      <ToggleSound/>
      <ToggleScript/>
      <ToggleTheme/>
      <AuthButton />
    </Toolbar>
    {children}
  </AppBar>
);}

export default AppTitle;