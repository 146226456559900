import { Box, /*BoxProps*/ } from "@mui/material";
//import { ReactNode } from "react";

// type CenterBoxProps = BoxProps & ReactNode;

const CenterBox = ( props : any /*CenterBoxProps*/) => {
  return (
    // <Box sx={{ ...props.sx, flexGrow: 1 }}>
      <Box
        sx={props.sx}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {props.children}
      </Box>
    // </Box>
  );
};

export default CenterBox;
