import { useEffect, useReducer, useRef } from 'react'

const LOCALHOST_PREFIX = ""; //process.env.NODE_ENV !== 'production' ? "127.0.0.1/" : "";



export async function fetchWithError(url:string, init?:RequestInit) : Promise<Response> {
    
    let res = await fetch( LOCALHOST_PREFIX+url, init);
    if (!res.ok){
      throw new Error(`fetch from ${LOCALHOST_PREFIX + url} failed: ${res.statusText}`)
    }
    return res;
  }

  export async function fetchBytes(url: string, init?:RequestInit) : Promise<ArrayBuffer> {
    return (await fetchWithError(url, init)).arrayBuffer();
  }


  export async function fetchJson(url: string, init?:RequestInit) : Promise<any> {
    return (await fetchWithError(url, init)).json();
  }


  export async function fetchText(url: string, init?:RequestInit) : Promise<string> {
    return (await fetchWithError(url, init)).text();
  }

  export async function fetchBlob(url: string, init?:RequestInit) : Promise<Blob> {
    return (await fetchWithError(url, init)).blob();
  }

  interface State<T> {
    data?: T
    error?: Error
  }
  
  type Cache<T> = { [url: string]: T }
  
  // discriminated union type
  type Action<T> =
    | { type: 'loading' }
    | { type: 'fetched'; payload: T }
    | { type: 'error'; payload: Error }
  

export function useFetch<T = unknown>(url?: string, options?: RequestInit): State<T> {
    const cache = useRef<Cache<T>>({})
  
    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef<boolean>(false)
  
    const initialState: State<T> = {
      error: undefined,
      data: undefined,
    }
  
    // Keep state logic separated
    const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
      switch (action.type) {
        case 'loading':
          return { ...initialState }
        case 'fetched':
          return { ...initialState, data: action.payload }
        case 'error':
          return { ...initialState, error: action.payload }
        default:
          return state
      }
    }
  
    const [state, dispatch] = useReducer(fetchReducer, initialState)
  
    useEffect(() => {
      // Do nothing if the url is not given
      if (!url) return
  
      cancelRequest.current = false
  
      const fetchData = async () => {
        dispatch({ type: 'loading' })
  
        // If a cache exists for this url, return it
        if (cache.current[url]) {
          dispatch({ type: 'fetched', payload: cache.current[url] })
          return
        }
  
        try {
          const response = await fetch(url, options)
          if (!response.ok) {
            throw new Error(response.statusText)
          }
  
          const data = (await response.json()) as T
          cache.current[url] = data
          if (cancelRequest.current) return
  
          dispatch({ type: 'fetched', payload: data })
        } catch (error) {
          if (cancelRequest.current) return
  
          dispatch({ type: 'error', payload: error as Error })
        }
      }
  
      void fetchData()
  
      // Use the cleanup function for avoiding a possibly...
      // ...state update after the component was unmounted
      return () => {
        cancelRequest.current = true
      }
    }, [url, options])
  
    return state
  }

  