export function cir2lat(text:string):string{
    text = text
        .replace(/А/g, 'A')
        .replace(/Б/g, 'B')
        .replace(/В/g, 'V')
        .replace(/Г/g, 'G')
        .replace(/Д/g, 'D')
        .replace(/Ђ/g, 'Đ')
        .replace(/Е/g, 'E')
        .replace(/Ж/g, 'Ž')
        .replace(/З/g, 'Z')
        .replace(/И/g, 'I')
        .replace(/Ј/g, 'J')
        .replace(/К/g, 'K')
        .replace(/Л/g, 'L')
        // lowercase cyrillic range
        .replace(/Љ([\u0430-\u045f])/g, 'Lj$1')
        .replace(/Љ/g, 'LJ')
        .replace(/М/g, 'M')
        .replace(/Н/g, 'N')
        // lowercase cyrillic range
        .replace(/Њ([\u0430-\u045f])/g, 'Nj$1')
        .replace(/Њ/g, 'NЈ')
        .replace(/О/g, 'O')
        .replace(/П/g, 'P')
        .replace(/Р/g, 'R')
        .replace(/С/g, 'S')
        .replace(/Т/g, 'T')
        .replace(/Ћ/g, 'Ć')
        .replace(/У/g, 'U')
        .replace(/Ф/g, 'F')
        .replace(/Х/g, 'H')
        .replace(/Ц/g, 'C')
        .replace(/Ч/g, 'Č')
        // lowercase cyrillic range
        .replace(/Џ([\u0430-\u045f])/g, 'Dž$1')
        .replace(/Џ/g, 'DŽ')
        .replace(/Ш/g, 'Š')
        .replace(/а/g, 'a')
        .replace(/б/g, 'b')
        .replace(/в/g, 'v')
        .replace(/г/g, 'g')
        .replace(/д/g, 'd')
        .replace(/ђ/g, 'đ')
        .replace(/е/g, 'e')
        .replace(/ж/g, 'ž')
        .replace(/з/g, 'z')
        .replace(/и/g, 'i')
        .replace(/ј/g, 'j')
        .replace(/к/g, 'k')
        .replace(/л/g, 'l')
        .replace(/љ/g, 'lj')
        .replace(/м/g, 'm')
        .replace(/н/g, 'n')
        .replace(/њ/g, 'nj')
        .replace(/о/g, 'o')
        .replace(/п/g, 'p')
        .replace(/р/g, 'r')
        .replace(/с/g, 's')
        .replace(/т/g, 't')
        .replace(/ћ/g, 'ć')
        .replace(/у/g, 'u')
        .replace(/ф/g, 'f')
        .replace(/х/g, 'h')
        .replace(/ц/g, 'c')
        .replace(/ч/g, 'č')
        .replace(/џ/g, 'dž')
        .replace(/ш/g, 'š');

  // ToDo: ispravi velika/mala slova u Lj, Nj, Dž
  
    return text;
};