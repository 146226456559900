import React, {ReactNode, forwardRef, useRef} from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
//import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Zoom from "@mui/material/Zoom";
import { TransitionProps } from "@mui/material/transitions";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Breakpoint } from "@mui/material";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} timeout={{ enter: 5000, exit: 100 }} {...props} />;
});

interface IImageDialogProps extends DialogProps {
  buttonText?: string;
  image?: string | ReactNode;
  imgMaxHeight?: string | number;
  onClose: () => void;
  onAction?: () => void;
  fullScreenBelow?: number | Breakpoint;
}

export function ImageDialogZoom({
  buttonText = "Ok",
  image,
  imgMaxHeight = 128,
  onClose,
  onAction = onClose,
  fullScreenBelow = "md",
  ...rest
}: IImageDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(fullScreenBelow));
  
  const textRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Dialog
        // open={rest.open}
        {...rest}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        onClose={onClose}
      >
        <DialogTitle>
          {image && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img style={{ maxHeight: imgMaxHeight }} src={image as string} alt="Dialog" />
            </div>
          )}
        </DialogTitle>
        <DialogContent ref={textRef} 
        // onScroll={handleScroll} 
        >
            {rest.children}
          <Stack alignItems="center">
              <Button autoFocus variant="contained" onClick={onAction}>{buttonText}</Button>
          </Stack>
        </DialogContent>
        {/* <DialogActions>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
