//import {Children} from 'react';
import MuiButton, { ButtonProps } from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTransliteration } from "../providers/transliterate";

export default function Button(
  props: ButtonProps & { tooltipText?: string }
) {
  const tl = useTransliteration();

  const {tooltipText="", title, children, ...other} = props;

  if (typeof children !== 'string'){
    console.warn("Only the children of type 'string' will be transliterated!");
  }

  return (
    <Tooltip title={tl(tooltipText)}>
      <span>
        <MuiButton {...other} title={tl(title)}>
          {typeof children === "string" ? tl(children) : children}
        </MuiButton>
      </span>
    </Tooltip>
  );
}
