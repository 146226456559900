import { WRITE_LOG_DB, WRITE_ANSW_DB, WRITE_CONFIG_DB, READ_LOG_DB, GET_LOCATION_FROM_IP, GET_USED_COUNTRIES, StringWithId, GET_FILTERED_AVERAGE, } from '../constants';
import {IATConfig, IATLogEntry, IATResponse, IAveragesForFilterResponse, IFilterProps, ILocation} from '../iat-types';
import { fetchJson, fetchWithError } from './fetch';

export async function getLocationFromServer():Promise<ILocation>{
    return await (await fetchWithError( GET_LOCATION_FROM_IP )).json() as ILocation;
}

export async function getUsedCountries(testId:string):Promise<StringWithId[]>{
   let resp = await fetchWithError(GET_USED_COUNTRIES + testId);
   return await resp.json() as StringWithId[];
}

export async function writeIatLog(testId: string, logEntry: IATLogEntry){
    const formData = new FormData();
    formData.append('id', testId);
    formData.append('data', JSON.stringify(logEntry) );
    let res = await fetchWithError( WRITE_LOG_DB, {method: "POST", body: formData});
    return res.clone();

  }

export async function writeIatAns(testId: string, answers: IATResponse[]){
    const formData = new FormData();
    formData.append('id', testId);
    formData.append('data', JSON.stringify(answers) );
    let res = await fetchWithError( WRITE_ANSW_DB, {method: "POST", body: formData});
    return res.clone();

}

export async function writeIatConfig(config: IATConfig){
  const formData = new FormData();
  formData.append('data', JSON.stringify(config) );
  const cmd = `?cmd=add&id=${config.id}`;
  let res = await fetchWithError( WRITE_CONFIG_DB + cmd, {method: "POST", body: formData});
  return res.clone();
}

export async function toggleConfigActive(id: string, active: number){
  const cmd = `?cmd=activate&id=${id}&val=${active}`;
  let res = await fetchWithError( WRITE_CONFIG_DB + cmd);
  return res.clone();
}

export async function deleteConfig(id: string){
  const cmd = `?cmd=delete&id=${id}`;
  let res = await fetchWithError( WRITE_CONFIG_DB + cmd);
  return res.clone();
}


export const getAverageForFilter = async (testId: string, filterBy: IFilterProps):Promise<IAveragesForFilterResponse> => {
  const params = new URLSearchParams({id: testId});
  //const {country, gender, edu, finance, citySize, group_id} = filterBy;
  
  Object.entries(filterBy).forEach(f => {
    if(f[1] !=="")
      params.set(f[0], f[1])
  });

  //console.time("getAvg")
  const res = await fetchJson(GET_FILTERED_AVERAGE + "?" + params ) as IAveragesForFilterResponse;
  if (res.count < 5 ){
    res.averageDScore = 0;
    res.averageResponseTime = NaN;
  }
  //await _wait(1000);
  //console.timeEnd("getAvg")
  return res;
}

  export async function readIatLog(testId:string):Promise<IATLogEntry[]>{
    return await (await fetchWithError( READ_LOG_DB + testId )).json();
  }
 