import { cir2lat } from "./cir2lat"

const autoFillMap = new Map([
    ["ime", "given-name"],
    ["prezime", "family-name"],
    ["nadimak", "nickname"],
    ["kompanija", "organization-title"],
    ["firma", "organization-title"],
    ["grad", "address-level2"],
    ["mesto", "address-level2"],
    ["zemlja", "country-name"]
]);

export const mapNameToAutocomplete = (text:string) => {
    const label = cir2lat(text).toLowerCase();
    return autoFillMap.get(label);
}