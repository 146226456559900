import { Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CenterBox from "../layout/centerbox";
import { useTransliteration } from "../providers/transliterate";

const CenteredSpinner = ({text=""}) => {
    const tl=useTransliteration();
    return (
    <Stack spacing={3} style={{paddingTop: 16}}>
    <CenterBox>
        <CircularProgress />
    </CenterBox>
    {text && <CenterBox>
        <Typography>{tl(text)}</Typography>
    </CenterBox>}
    </Stack>
)};

export default CenteredSpinner;