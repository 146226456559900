const PHP_SUFFIX = process.env.NODE_ENV === 'production' ? ".php" : "";

const noop = (...args:any[])=>{}

export const _log = process.env.NODE_ENV === 'production' ? noop : console.log;

export const _wait = (ms:number) => process.env.NODE_ENV === 'production' ? noop : new Promise<void>(resolve=>setTimeout(resolve, ms))

export const _err = console.error;

export const MAX_ZIP_SIZE = 5; // limit zip file size to this much MB

export const DB_PATH = '/db';
export const GET_CFG_DB = DB_PATH + '/getconfigs' + PHP_SUFFIX;
export const WRITE_LOG_DB = DB_PATH + "/writelog"  + PHP_SUFFIX;
export const WRITE_ANSW_DB = DB_PATH + "/writeans"  + PHP_SUFFIX;
export const WRITE_CONFIG_DB = DB_PATH + "/admincfg"  + PHP_SUFFIX;
export const GET_XLSX_FILE = DB_PATH + "/xlsx-export"  + PHP_SUFFIX + "?id=";
export const READ_LOG_DB = DB_PATH + "/readlog"   + PHP_SUFFIX + "?id=";
export const GET_AGE_GROUPS_DB = DB_PATH + "/agegroups" + PHP_SUFFIX;
export const GET_LOCATION_FROM_IP = DB_PATH + "/getlocation" + PHP_SUFFIX;
export const GET_USED_COUNTRIES = DB_PATH + "/getcountries" + PHP_SUFFIX + "?id=";
export const GET_FILTERED_AVERAGE = DB_PATH + "/getavg" + PHP_SUFFIX;
export const GET_CHART_DATA = DB_PATH + "/chartdata" + PHP_SUFFIX + "?id=";
export const GET_MAP_DATA = DB_PATH + "/readgeo" + PHP_SUFFIX;
export const ARCHIVE_DB = DB_PATH + "/archive"  + PHP_SUFFIX;

export const READ_LOGS_FOR_EXCELL_EXPORT = DB_PATH + "/readlog4xls"   + PHP_SUFFIX + "?id=";

export const CONFIG_SCHEMA_URL = "/assets/config-schema.json";
export const BASIC_SURVEY_DEF = "/assets/survey-basic.json";

export const ADMIN_URL = "/researcher";

// where to look for images
export const IMAGE_PATH = (dir:string) => `/templates/${dir}/img/`;

export type StringWithId = { 
  id: string, 
  text: string,
  extra?: string
};

export const selectAllOption : StringWithId = {
  id: "all",
  text: "Све"
}

export const emotionLabels : StringWithId[] = [
  {id: "1", text:"Мрзим"},
  {id: "2", text: "Не волим"},
  {id: "3", text: "Неутрално"},
  {id: "4", text: "Волим"},
  {id: "5", text: "Обожавам"}
];

  export const financialLabels : StringWithId[] = [
    {id: "1", text: "Изузетно лоше", extra: "изузетно незадовољних"},
    {id: "2", text: "Јако лоше", extra: "јако незадовољних"},
    {id: "3", text: "Лоше", extra: "незадовољних"},
    {id: "4", text: "Неутрално", extra: "ни задовољних ни незадовољних"},
    {id: "5", text: "Добро", extra: "задовољних"},
    {id: "6", text: "Јако добро", extra: "веома задовољних"},
    {id: "7", text: "Изузетно добро", extra: "изузетно задовољних"},
  ];

export const citySizeLabels : StringWithId[] = [
  {id: "l", text: "У великом граду", extra: "из великог града"},
  {id: "m", text: "У малом граду", extra: "из малог града"},
  {id: "s", text: "На селу", extra: "са села"},
];


export const educationLabels : StringWithId[] = [
    {id: "1", text: "Незавршена основна школа", extra: "са незавршеном основном школом"},
    {id: "2", text: "Основна школа", extra: "са завршеном основном школом"},
    {id: "3", text: "Средња школа", extra: "са завршеном средњом школом"},
    {id: "4", text: "Факултет", extra: "са завршеним факултетом"},
    {id: "5", text: "Мастер", extra: "са завршеним мастером"},
    {id: "6", text: "Докторат", extra: "са докторатом"}
  ];


  export const genderMap  : StringWithId[] = [
      {id: "male", text: "Мушки", extra: "мушког"},
      {id: "female", text: "Женски", extra: "женског"},
      //{id: "other", text: "Друго/Остало", extra: "неодређеног"}
    ];

  // export const ageGroups = [
  //   { min:  0, max:  12, group: "0–12",  name: "Деца" },
  //   { min: 13, max:  19, group: "13–19", name: "Тинејџери" },
  //   { min: 20, max:  35, group: "20–35", name: "Млади" },
  //   { min: 36, max:  55, group: "36–55", name: "Средње године" },
  //   { min: 56, max:  70, group: "56–70", name: "Старији" },
  //   { min: 71, max: 999, group: "71+",   name: "Стари"}
  // ];

  // export const findAgeGroup = (age:number) => {
  //   const idx = ageGroups.findIndex(e => age >= e.min && age <= e.max);
  //   if (idx === -1 ) return undefined;
  //   return {...ageGroups[idx]};
  // }

  export const FAB_MARGIN = 32;
  export const FAB_SIZE = 72;