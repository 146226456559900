import { IMAGE_PATH } from "../constants";
import { IATConfig } from "../iat-types";
import { fetchBlob } from "../providers/fetch";
import { isUrl, isDataUrl } from "./is-url";

const convertBlobToBase64 = (blob:Blob) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
});

const getDataUrl = async (path:string):Promise<string> => {
  const b = await fetchBlob(path);
  return await convertBlobToBase64(b);
}

export const hydrateConfig = async (cfg:IATConfig):Promise<IATConfig> => {
  
    const hydrate = (list: string[]) => {     
      return Promise.all( list.map( async (el) => {
        if (isUrl(el)){
          if (isDataUrl(el)){
             return Promise.resolve(el);
          }else{
             return getDataUrl(IMAGE_PATH(id) + el);
          }
        }
        return Promise.resolve(el);
      }));
    };

    if (cfg?.hydrated) return cfg;

    const { id } = cfg;
    const {cat1, cat2, catA, catB} = cfg.categories;

    return {
      ...cfg,
      hydrated: true,
      categories: {
        cat1: {...cat1, items: await hydrate(cat1.items)},
        cat2: {...cat2, items: await hydrate(cat2.items)},
        catA: {...catA, items: await hydrate(catA.items)},
        catB: {...catB, items: await hydrate(catB.items)},
      }
    };
  
  }
  