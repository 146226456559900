import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTransliteration } from './transliterate';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';


export default function ConfirmDialog({open, email, onConfirm, onClose}:{open: boolean, email:string, onConfirm: ()=>void, onClose: ()=>void}) {

  const tl = useTransliteration();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <div>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {tl("Ресетовање лозинке")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                {tl(`На адресу ${email} ће бити послата порука са упутством које треба да пратите како би ваша лозинка била ресетована. `)}
                {tl(`Да ли сте сигурни?`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
              onConfirm();
            }}>{tl("Слажем се")}</Button>
          <Button onClick={onClose}>
            {tl("Откажи")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
