
import {Localization} from '@mui/material/locale';

export const srCyrl: Localization = {

    components: {
      MuiBreadcrumbs: { defaultProps: {
        expandText: 'Прикажи путању',
      }},
      MuiTablePagination: { defaultProps: {
        getItemAriaLabel: (type) => {
          if (type === 'first') {
            return 'Иди на прву страну';
          }
          if (type === 'last') {
            return 'Иди на последњу страну';
          }
          if (type === 'next') {
            return 'Иди на следећу страну';
          }
          // if (type === 'previous') {
          return 'Иди на претходну';
        },
        labelRowsPerPage: 'Редова по страни:',
        labelDisplayedRows: ({ from, to, count }) =>
    `${from}–${to} од ${count !== -1 ? count : `више од ${to}`}`,
      }},
      MuiRating: { defaultProps: {
        getLabelText: value => `${value} Звезд${value >1 && value<5 ? 'е' : 'а'}`,
        emptyLabelText: 'Празно',
      }},
      MuiAutocomplete: { defaultProps: {
        clearText: 'Обриши',
        closeText: 'Затвори',
        loadingText: 'Учитавање…',
        noOptionsText: 'Нема опција',
        openText: 'Отвори',
      }},
      MuiAlert: { defaultProps: {
        closeText: 'Затвори',
      }},
      MuiPagination: {  defaultProps: {
        'aria-label': 'Pagination navigation',
        getItemAriaLabel: (type, page, selected) => {
          if (type === 'page') {
            return `${selected ? 'страна ' : 'Иди на страну '}${page}`;
          }
          if (type === 'first') {
            return 'Иди на прву страну';
          }
          if (type === 'last') {
            return 'Иди на последњу страну';
          }
          if (type === 'next') {
            return 'Иди на следећу страну';
          }
          // if (type === 'previous') {
          return 'Иди на претходну страну';
        },
      }},
    },

};


export const srLatn: Localization = {

    components: {
      MuiBreadcrumbs: { defaultProps: {
        expandText: 'Prikaži putanju',
      }},
      MuiTablePagination: { defaultProps: {
        getItemAriaLabel: (type) => {
          if (type === 'first') {
            return 'Idi na prvu stranu';
          }
          if (type === 'last') {
            return 'Idi na poslednju stranu';
          }
          if (type === 'next') {
            return 'Idi na sledeću stranu';
          }
          // if (type === 'previous') {
          return 'Idi na prethodnu';
        },
        labelRowsPerPage: 'Redova po strani:',
        labelDisplayedRows: ({ from, to, count }) =>
    `${from}–${to} od ${count !== -1 ? count : `više od ${to}`}`,
      }},
      MuiRating: { defaultProps: {
        getLabelText: value => `${value} Zvezd${value >1 && value<5 ? 'e' : 'a'}`,
        emptyLabelText: 'Prazno',
      }},
      MuiAutocomplete: { defaultProps: {
        clearText: 'Obriši',
        closeText: 'Zatvori',
        loadingText: 'Učitavanje…',
        noOptionsText: 'Nema opcija',
        openText: 'Otvori',
      }},
      MuiAlert: { defaultProps: {
        closeText: 'Zatvori',
      }},
      MuiPagination: {  defaultProps: {
        'aria-label': 'Pagination navigation',
        getItemAriaLabel: (type, page, selected) => {
          if (type === 'page') {
            return `${selected ? 'strana' : 'Idi na stranu'} ${page}`;
          }
          if (type === 'first') {
            return 'Idi na prvu stranu';
          }
          if (type === 'last') {
            return 'Idi na poslednju stranu';
          }
          if (type === 'next') {
            return 'Idi na sledeću stranu';
          }
          // if (type === 'previous') {
          return 'Idi na prethodnu stranu';
        },
      }},
    },

};