import { atom, useRecoilState, useRecoilValue } from 'recoil';
import IconButton from '@mui/material/IconButton';
import createSvgIcon from '@mui/material/utils/createSvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { cir2lat } from '../utils/cir2lat';

type AppScript = "cyr" | "lat";

const getAppScript = ():AppScript => {
    const s = localStorage.getItem('APP_SCRIPT');
    return s === "lat" ? "lat" : "cyr";
}

const appScript = atom<AppScript>({
  key: 'appScript',
  default: getAppScript()
})


const useTransliteration = () => {
    const script = useRecoilValue(appScript);

    const tl = (s?:string):string => {
        if (!s) return "";
        if (script === "lat")
            return cir2lat(s);
        return s;
    }
    
    return tl;
}

const CyrIcon = createSvgIcon(
  <path d="M3,21H1V15H7v2H4.52A9,9,0,0,0,21,12h2A11,11,0,0,1,3,18.33ZM1,12A11,11,0,0,1,21,5.67V3h2V9H17V7h2.48A9,9,0,0,0,3,12Zm13.371,5V13.647a1.333,1.333,0,0,0-.265-.945,1.19,1.19,0,0,0-.873-.269H11.159V17H8.814V9h-2.1V7h7.766V9H11.159v1.475h2.234a3.513,3.513,0,0,1,2.451.793,2.827,2.827,0,0,1,.873,2.205V17Z"/>,
  'CyrIcon'
)

const LatIcon = createSvgIcon(
  <path d="M3,21H1V15H7v2H4.52A9,9,0,0,0,21,12h2A11,11,0,0,1,3,18.33ZM1,12A11,11,0,0,1,21,5.67V3h2V9H17V7h2.48A9,9,0,0,0,3,12Zm8.14,5V7h2.352v8h4.5v2Z"/>,
  'LatIcon'
)

const ToggleScript = () => {
  const [script, setScript] = useRecoilState(appScript);

  const tl = useTransliteration();

  const toggleScript = () => {
      let newScript = script;
      if (script === "cyr") newScript = "lat"; else newScript = "cyr";
    localStorage.setItem('APP_SCRIPT', newScript);
    setScript(newScript);
  }

  return (
    <Tooltip title={ script ==="cyr" ? tl("> Латиница") : tl("> Ћирилица") }>
    <IconButton sx={{ ml: 1 }} onClick={toggleScript} color="inherit">
        {script === "cyr" ? <LatIcon /> : <CyrIcon/>}
    </IconButton>
    </Tooltip>
  );
}

export {ToggleScript, useTransliteration, appScript};
