import { useMemo, Suspense } from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./pages/routes";
import { useRecoilValue } from "recoil";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import { appIsDarkMode, mainTheme } from "./theme";
import CenteredSpinner from "./components/centered-spinner";
import { useTransliteration, appScript } from "./providers/transliterate";
import { srCyrl, srLatn } from "./providers/locales";
import { SnackbarProvider } from "notistack";

function App() {
  // Get OS-level preference for dark mode
  //const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const tl = useTransliteration();

  // state: boolean ; true == use dark mode
  const darkMode = useRecoilValue(appIsDarkMode);
  const script = useRecoilValue(appScript);

  const themeString = (b: boolean | null) => (b ? "dark" : "light");
  const locale = script === "lat" ? srLatn : srCyrl;

  const theme = useMemo(
    () =>
      createTheme(
        {
          ...mainTheme,
          palette: {
            mode: themeString(darkMode),
            ...mainTheme.palette,
          },
        },
        locale
      ),
    [darkMode, locale]
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Suspense
            fallback={
              <CenteredSpinner
                text={tl("Само тренутак…")}
              />
            }
          >
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
