import { Box, CircularProgress, Stack, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import {InstructionsMarkdown} from '../components/instructions';
import AppTitle from '../layout/appbar';
import { fetchText } from '../providers/fetch';
import { useTransliteration } from '../providers/transliterate';

const NoMatch = ({error=404}) => {
    const navigate = useNavigate();
    
    const [instructions, setInstructions] = useState<string | null>(null);
    
    const tl = useTransliteration();

    useEffect(()=>{
        fetchText("/assets/md/error-page.md").then((t) => setInstructions(t));
    }, [error]);

    return (

        <Box sx={{ flexGrow: 1 }}>
        <AppTitle title={`Грешка ${error}`} />
  
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
         // minHeight="100vh"
        >
          {instructions === null ? (
            <CircularProgress />
          ) : (
            <Stack>
              <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
                <InstructionsMarkdown
                  mdText={instructions}
                />
              </Box>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/", {replace: true});
                }}
              >
                {tl("На почетак")}
              </Button>
  
            </Stack>
          )}
        </Box>
      </Box>
    );
}

export default NoMatch;