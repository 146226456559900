import './instructions.css';

import Mustache from "mustache";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { IATCategories, Padezi } from "../iat-types";
import { isTouchDevice } from "../utils/is-touch";
import { useTransliteration } from '../providers/transliterate';
import { ReactNode, useEffect, useState } from 'react';
import { fetchText } from '../providers/fetch';
import CenteredSpinner from './centered-spinner';
import { useRecoilValue } from 'recoil';
import { currConfigObject } from '../providers/app-state';
import { ImageDialogZoom } from './image-dialog';
import { useTheme } from '@mui/material';

type TMustache = {
    [k in IATCategories]: Padezi;
}

interface InstructionsProps{
    mdText: string;
    imageUrl?: string;
    mustache?: TMustache; //Partial<keyof IATConfig>;
}

interface IInstructionParams{
    md: string;
    image?: string | ReactNode;
}

const roundInstructionsTable = new Map<string, IInstructionParams>([
    ["intro", {md: "/assets/md/intro.md", image: "/assets/img/icon-app-performance.svg"}],
    ["done", {md: "/assets/md/test-done.md", image: "/assets/img/icon-finishing-flag.svg" }],
    ["error", {md: "/assets/md/error-page.md"}]

]);

interface InstructionDialogProps{
    round: string | number;
    onClose: ()=>void;
    open: boolean;
}

export const InstructionDialog = ({open=false, round, onClose}:InstructionDialogProps ) => {

    const cfg = useRecoilValue(currConfigObject);

    const [instructions, setInstructions] = useState<IInstructionParams|null>(null);
    const [markdown, setMarkdown] = useState<string|null>(null);

    useEffect( () => {
        let isMounted = true;
        if (isMounted){
            if (typeof round === 'number'){
                setInstructions({md:`/assets/md/round-${round}.md`, image: `/assets/img/round-image.svg` });
            }else if ( roundInstructionsTable.has(round) ){
                setInstructions(roundInstructionsTable.get(round) || null);
            }
        }
        return ()=>{isMounted=false;}
    }, [round]);

    useEffect(()=>{
        let isMounted=true;
        if (instructions){
            fetchText(instructions.md).then( 
                t => {
                    if (isMounted) setMarkdown(t)
                } );
        }
        return () => {isMounted=false};
    }, [instructions]);

    
    if (markdown === null)
    return <CenteredSpinner/>
    
    
    return (
        <ImageDialogZoom open={open} image={instructions?.image} onClose={onClose}>
            <InstructionsMarkdown
              mdText={markdown}
              mustache={{
                catA: cfg.categories.catA.label,
                catB: cfg.categories.catB.label,
                cat1: cfg.categories.cat1.label,
                cat2: cfg.categories.cat2.label,
              }}
              />
        </ImageDialogZoom>
    );
  };

export const InstructionsMarkdown = ({mdText, mustache}:InstructionsProps) => {

    const tl = useTransliteration();
    
    const primary = useTheme().palette.primary.main;

    const replacer = { ...mustache, isTouch: isTouchDevice()};
    const text = Mustache.render(mdText, replacer);

    return  (
            <ReactMarkdown 
                className="printableContainer"
                children={tl(text)}
                remarkPlugins={[remarkGfm]}
                components={{
                    strong: ({node, ...props}) => <b style={{color: primary }} {...props} />,
                    table: ({node, ...props}) => <table width="100%" {...props} />
                    }}
            />
        );
};

