import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {ToggleButtonGroupProps} from '@mui/material/ToggleButtonGroup';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import { IGender } from '../iat-types';
import { useTransliteration } from '../providers/transliterate';

interface GenderToggleProps extends Omit<ToggleButtonGroupProps, "onChange"> {
  value?: IGender;
  onChange: (newVal:IGender)=>void;
}

export default function GenderToggleButton({value="male", onChange}:GenderToggleProps) {
  const [gender, setGender] = React.useState<IGender>(value);

  const tl = useTransliteration();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newGender: IGender,
  ) => {
    if (newGender!==null){
      setGender(newGender);
      onChange(newGender)
    }
  };

  return (
    <ToggleButtonGroup
      value={gender}
      exclusive
      onChange={handleChange}
      aria-label="Gender"
      size="small"
    >
      <ToggleButton value="male" color="primary" title={tl("Мушко")}><MaleIcon/></ToggleButton>
      <ToggleButton value="female" color="secondary" title={tl("Женско")}><FemaleIcon/></ToggleButton>
    </ToggleButtonGroup>
  );
}