import { atom, useRecoilState, useRecoilValue } from 'recoil';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IconButton from '@mui/material/IconButton';
import {useSound} from 'use-sound';
import Tooltip from '@mui/material/Tooltip';
import { useTransliteration } from './transliterate';
import { SpriteMap } from 'use-sound/dist/types';

const SOUND_EFFECTS = "/assets/sfx/sfx.mp3";

// name: [start, duration] (ms)
const soundSprites: SpriteMap = {
  warning: [0, 900],
  click: [998, 115],
  dblclick: [2003, 200],
  buzzer: [2994, 335],
  swipe: [4045, 724],
};


const getAppSoundState = () => {
    const s = localStorage.getItem('APP_SOUND');
    return s === "on" ? true : false;
}

const appSoundState = atom({
    key: 'appSoundState',
    default: getAppSoundState()
})

const useSfx = (id: string) => {

      const [sfx] = useSound(SOUND_EFFECTS, {sprite: soundSprites});
      const enabled = useRecoilValue(appSoundState);
      
      if (enabled){
        if (Object.keys(soundSprites).includes(id)){
          return ()=>sfx({id: id});
        }else{
          console.warn(`SoundEffects: no such id (${id}). Valid sounds are [${Object.keys(soundSprites).join(", ")}]`);
        }
      
    }
    return ()=>{};
}

// const userInteracted = () => window.sessionStorage.getItem("CLICKED") === "1";

// const recordUserInteraction = () => {
//   if(userInteracted()) return;
//   window.onload = ()=> {
//     "click mousedown contextmenu touch".split(" ").forEach(ev=>{
//       window.addEventListener(ev, (e)=> {
//         window.sessionStorage.setItem("CLICKED", "1");
//         _log(e.type);
//       } );

//     })
//   }
// }

// recordUserInteraction();

const ToggleSound = () => {
  const tl = useTransliteration();
  const [sound, setSound] = useRecoilState(appSoundState);
  const click = useSfx("click");
  
  const toggleSound = () => {

    const newSound = !sound;
    localStorage.setItem('APP_SOUND', newSound ? "on" : "off");
    setSound(newSound);
    if (!newSound) click();
  }


  return (
    <Tooltip title={ sound ? tl("Искључи звук") : tl("Укључи звук") }>
    <IconButton sx={{ ml: 1 }} onClick={toggleSound} color="inherit">
        { sound ? <VolumeUpIcon /> : <VolumeOffIcon/>}
    </IconButton>
    </Tooltip>
  );
}

export {useSfx, ToggleSound };
